html {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

body {
	display: flex;
	flex: 1;
	overflow: hidden;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'LiGothic', 'FangSong', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	display: flex;
	flex: 1;
	overflow: hidden;
}
